import React from 'react';
import { graphql, Link } from 'gatsby';
import { Rows, SiteMetadata, TypeButtons } from '../components';
import { Layout } from '../layouts/Layout';

export default ({ data, pageContext: { previousPagePath, nextPagePath }, ...props }) => {
  return (
    <Layout>
      <SiteMetadata
        title="Theatre related Books"
        description="Theatre related books"
        path={props.location.pathname}
      />
      <div className="mt-8 mx-4 text-indigo-800 text-base">
        <h2 className="text-2xl font-bold text-secondary mb-1 text-indigo-600">
          Theatre-related Books
        </h2>
        <h3 className="text-gray-600">
          Books based on a staged theatre show, or books that have staged theatre shows created based on them. Also includes scripts from staged shows.
        </h3>
      </div>
      <Rows nodes={data.theatreBooks.nodes} />
      <div className="container pt-4 pb-12 overflow-hidden justify-center align-center flex leading-tight text-white">
        {previousPagePath && <Link to={previousPagePath} className="inline-block rounded-lg px-4 py-3 bg-indigo-600 hover:bg-indigo-500 font-semibold shadow-lg mx-2">
          « Previous
        </Link>}
        {nextPagePath && <Link to={nextPagePath} className="inline-block rounded-lg px-4 py-3 bg-indigo-600 hover:bg-indigo-500 font-semibold shadow-lg mx-2">
          Next »
        </Link>}
      </div>
      <TypeButtons title="You might also be interested in..."></TypeButtons>
    </Layout>
  );
};

export const query = graphql`
  query BooksQuery($booksTableName: String!, $skip: Int!, $limit: Int!) {
    theatreBooks: allAirtable(
      filter: {
        table: { eq: $booksTableName }
      }
      skip: $skip
      limit: $limit
    ) {
      nodes {
        data {
          tags
          image {
            ...HorizontalCardImageFragment
          }
          name
          url
          summary
        }
      }
    }
  }
`;
